import {
	type ResponsiveValue,
	tempo,
	Image as ChakraImage,
	type ImageProps as ChakraImageProps,
} from '@tempo/core';
import {getImageProps, type ImageProps as NextImageProps} from 'next/image';
import {config} from '@modules/config';
import {useNextJsSize} from './hooks';

type CDNImageDirectory = 'misc' | 'playlist' | 'user' | 'cover' | 'artist';

/**
 * A fake src that should contain the hash of the image and the fallback format.
 * We will use it to create the real picture element containing webp sources and
 * fallback format source.
 */
export type CDNSrc = `${string}.${'png' | 'jpg' | 'webp'}`;

export const Picture = tempo('picture', {baseStyle: {w: '100%', h: 'auto'}});

function getHashAndExt(src: CDNSrc) {
	const [hash, ext] = src.split('.');
	return {hash, ext};
}

function deezerImageLoader(dir: CDNImageDirectory = 'misc') {
	return function (opt: {src: string; quality?: number; width: number}) {
		const {hash, ext} = getHashAndExt(opt.src as CDNSrc);
		return `${config.get('deezerImagesUrl')}/${dir}/${hash}/0x${opt.width}-000000-${opt.quality ?? '75'}-0-0.${ext}`;
	};
}

export const miscLoader = deezerImageLoader('misc');
type SupportedNextImageProps = Pick<
	NextImageProps,
	'alt' | 'quality' | 'priority'
>;
type PictureProps = React.ComponentProps<typeof Picture>;

/**
 * Props common to both type of images
 */
type BaseImageProps = SupportedNextImageProps &
	Omit<PictureProps, 'width' | 'height' | 'h' | 'w' | 'aspectRatio'> &
	Pick<ChakraImageProps, 'objectFit'> & {
		directory?: CDNImageDirectory;
		src: CDNSrc;
	};

type FixedSizeProps = {
	width: number;
	height: number;
};

type RatioedSizeProps = Pick<PictureProps, 'w' | 'width' | 'h' | 'height'> & {
	aspectRatio: `${number}/${number}`;
	sizes?: ResponsiveValue<string>;
};

/**
 * If you know your image size is not going to move and will always have the same
 * size use this prop structure.
 * This will ensure minimal image generation (only x1 and x2 not one per breakpoint)
 * you won't have to send aspectRatio as it will be automatically determined from
 * your dimensions. This means that your image must respect the image's
 * original dimensions.
 */
type FixedSizeImageProps = BaseImageProps & FixedSizeProps;
export type RatioedImageProps = BaseImageProps & RatioedSizeProps;

function isFixedImage(
	props: FixedSizeProps | RatioedSizeProps,
): props is FixedSizeProps {
	return !('aspectRatio' in props);
}

export type ImageProps = RatioedImageProps | FixedSizeImageProps;
export function Image({
	children,
	directory,
	objectFit = 'contain',
	src,
	alt,
	priority,
	quality,
	...props
}: React.PropsWithChildren<ImageProps>) {
	const transformer = useNextJsSize();
	const [hash, fallbackType] = src.split('.');
	const nextImageProps: Omit<NextImageProps, 'src'> = {
		alt,
		loader: deezerImageLoader(directory),
		priority,
		quality,
	};
	let pictureProps: PictureProps = {};

	if (isFixedImage(props)) {
		const {width, height, ...remainingProps} = props;
		nextImageProps.width = width;
		nextImageProps.height = height;
		pictureProps.height = `${height}px`;
		pictureProps.width = `${width}px`;
		pictureProps = {
			...remainingProps,
		};
	} else {
		const {aspectRatio, sizes, ...remainingProps} = props;
		const [naturalWidth, naturalHeight] = aspectRatio.split('/');
		nextImageProps.sizes = sizes ? transformer(sizes) : '100vw';
		nextImageProps.width = parseInt(naturalWidth, 10);
		nextImageProps.height = parseInt(naturalHeight, 10);
		pictureProps = {
			...remainingProps,
		};
	}

	const {
		props: {srcSet: webpSrcSet},
	} = getImageProps({...nextImageProps, src: `${hash}.webp`});
	const {
		props: {
			srcSet: fallbackSrcSet,
			width: htmlWidth,
			height: htmlHeight,
			objectFit: _,
			fill: __,
			fetchPriority: ___,
			loading: ____,
			...rest
		},
	} = getImageProps({...nextImageProps, src: `${hash}.${fallbackType}`});

	return (
		<Picture
			position="relative"
			display="block"
			data-cy="next-image"
			{...pictureProps}
		>
			<source
				srcSet={webpSrcSet}
				sizes={nextImageProps.sizes}
				type="image/webp"
			/>
			<source
				srcSet={fallbackSrcSet}
				sizes={nextImageProps.sizes}
				type={`image/${fallbackType}`}
			/>
			{children}
			<ChakraImage
				height="100%"
				width="100%"
				fetchPriority={priority ? 'high' : 'auto'}
				loading={priority ? 'eager' : 'lazy'}
				objectFit={objectFit}
				htmlHeight={htmlHeight}
				htmlWidth={htmlWidth}
				{...rest}
			/>
		</Picture>
	);
}

export const HeroImage: React.FC<ImageProps> = (props) => {
	return <Image opacity=".5" priority objectFit="cover" {...props} />;
};
